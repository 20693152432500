import {Main} from './Main';
import {Order} from './Order';
import {Page} from './Page';
import {BrowserRouter, Router, Route, Switch,Link} from 'react-router-dom';
import { useCookies } from "react-cookie";
import './main.css';
function App() {
  const [cookies, setCookie] = useCookies(["auth"]);
  function handleCookie(){
    setCookie("auth", "ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmZhV1FpT2lJMk1UZ3lOREF6WXpFNE56azJNMlJqTm1FelpHSmpZV1VpTENKbWRXeHNibUZ0WlNJNkltRmtiV2x1SWl3aVpXMWhhV3dpT2lJaUxDSnNZWE4wWDNScGJXVmZjR0Z6YzNkdmNtUWlPakFzSW1saGRDSTZNVFl6TlRreU5qRTVPSDAuTml1aUJER2Nnek9uaXltVGdaR1RNRVg5ckIycHA3OThJMm9MYkpaRkxNWQ==", {
      path: "/"
    });
  }
  function test(){
    fetch('https://postfanpage.lalasoft.vn/api/v1/user/detail',
    {withCredentials: true,
    credentials: 'include',
    crossDomain: true})
    .then(response=>response.json())
    .then(data=>{
    })
  }
  return (
    <div className="page-container">
      <div className="page-containe-main"> 
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Main}></Route>
            <Route exact path="/post/:id" component={Order}></Route>
          </Switch>
        </BrowserRouter>
      </div>
    </div>
  );
}
export default App;
