import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import {CreateOrder} from './CreateOrder';
import ReactPaginate from 'react-paginate';
import './assets/css/codebase.css';
import moment from 'moment';
import { EditComment } from "./EditComment";

export class Order extends Component{
    constructor(props){
        super(props);
        this.state={order:[],product:[],totalPost:0,totalPage:0, selectedOption: "",
        addShowEditComment:false,page_selected:1,id_comment:'',code:'',type:0,status:0,row_in_page:15,is_auto_refresh:true,intervalId:0,obj_id:''
        }
        this.autorefreshList=this.autorefreshList.bind(this);
    }
    refreshList = (idpost,page_index) => {
        fetch('https://appcmtapi.lalasoft.vn/api/Comment/GetComment?idpost='+idpost+'&page='+page_index+'&limit='+this.state.row_in_page+'&code='+this.state.code+'&type='+this.state.type+'&status='+this.state.status)
        .then(response=>response.json())
        .then(data=>{
            this.setState({order:data.comments,totalPost:data.total,totalPage:((data.total/this.state.row_in_page))});
        });
    }
    autorefreshList = () => {
        fetch('https://appcmtapi.lalasoft.vn/api/Comment/GetComment?idpost='+this.props.match.params.id+'&page=1&limit='+this.state.row_in_page+'&code='+this.state.code+'&type='+this.state.type+'&status='+this.state.status)
        .then(response=>response.json())
        .then(data=>{
            this.setState({order:data.comments,totalPost:data.total,totalPage:((data.total/this.state.row_in_page))});
        });
    }
    getProduct=(idpost)=>{
        fetch('https://appcmtapi.lalasoft.vn/api/order/ListProduct?post_id='+idpost)
        .then(response=>response.json())
        .then(data=>{
            this.setState({product:data.products})
        });
    }
    handleChange = event => {
        this.setState({selectedOption: event.target.value});
    }
    handleChangeSelectCode=(event)=>{ 
        return new Promise(resolve=>{
            resolve(this.setState({code:event.target.value}));
        });
    }
    handleChangeSelectType=(event)=>{ 
        return new Promise(resolve=>{
            resolve(this.setState({type:event.target.value}));
        });
    }
    handleChangeSelectStatus=(event)=>{ 
        return new Promise(resolve=>{
            resolve(this.setState({status:event.target.value}));
        });
    }
    async refreshListChangeCode(event)
    {
        await this.handleChangeSelectCode(event);
        this.refreshList(this.props.match.params.id,this.state.page_selected)
    }
    async refreshListChangeType(event)
    {
        await this.handleChangeSelectType(event);
        this.refreshList(this.props.match.params.id,this.state.page_selected)
    }
    async refreshListChangeStatus(event)
    {
        await this.handleChangeSelectStatus(event);
        this.refreshList(this.props.match.params.id,this.state.page_selected)
    }
    componentDidMount(){
        this.refreshList(this.props.match.params.id,this.state.page_selected);
        this.getProduct(this.props.match.params.id);
        let intervalId= setInterval(()=>{
            if(this.state.is_auto_refresh===true)
            {
               this.autorefreshList();
            }
        },1000);
        this.state.intervalId=intervalId;
    }
    handlePageClick=(data)=>{
        if(data.selected+1==1)
        {
            this.state.is_auto_refresh=true;
        }
        else
        {
            this.state.is_auto_refresh=false;
        }
        this.state.page_selected=data.selected+1;
        this.refreshList(this.props.match.params.id,data.selected+1);
        
    }
    handleChangeSelectInPage=(event)=>{
        return new Promise(resolve=>{
            resolve(this.setState({row_in_page:event.target.value}));
        });
    }
    async refreshListChangeRowInPage(event)
    {
        await this.handleChangeSelectInPage(event);
        this.refreshList(this.props.match.params.id,this.state.page_selected)
    }
    goBack=()=>{
        clearInterval(this.state.intervalId);
       this.props.history.goBack();
    }
    render(){
        let {order}=this.state;
        let {product}=this.state;
        let addShowEditComment=()=>this.setState({addShowEditComment:false});
    return(
            <main id="main-container">
                <div class="block-header block-header-default">
                    <h1 class="block-title">Chi tiết bài viết : {this.state.totalPost}</h1>
                </div>
                <div class="mb-10">
                    <button type="button" class="btn btn-primary min-width-20"  toggle="tooltip"  data-placement="left" title="Trở về" data-original-title="Left Tooltip" 
                    onClick={()=>this.goBack()}>
                        <i class="fa fa-arrow-left"></i>
                    </button>
                    <button type="button" class="btn btn-primary min-width-20" toggle="tooltip"  data-placement="left" title="Làm mới" data-original-title="Left Tooltip" 
                    onClick={()=>this.refreshList(this.props.match.params.id,this.state.page_selected)}>
                        <i class="si si-reload fa-lg"></i>
                    </button>
                </div>
                <div class="mb-5">
                    <select class="form-control" name="code" style={{width:'150px',margin:'0px 20px 0px 20px',display:'inline-block'}} 
                    onChange={e=>this.refreshListChangeCode(e)}>
                            <option value="">Mã sản phẩm</option>
                            {product.map((item)=>{
                                return(
                                    <option value={item.code}>{item.code}</option>
                                )
                            })}
                    </select>
                    <select class="form-control" id="val-skill" name="type" style={{width:'150px',margin:'0px 10px 0px 0px',display:'inline-block'}} 
                    onChange={e=>this.refreshListChangeType(e)}>
                            <option value="0">Loại Comment</option>
                            <option value="1">Có SĐT</option>
                            <option value="2">Không có SĐT</option>
                    </select>
                    <select class="form-control" id="val-skill" name="status_comment" style={{width:'150px',margin:'0px 10px 0px 10px',display:'inline-block'}}
                    onChange={e=>this.refreshListChangeStatus(e)}>
                            <option value="0">Trạng thái</option>
                            <option value="1">Đang chờ</option>
                            <option value="2">Đã nhắn tin</option>
                            <option value="3">Đã chốt đơn</option>
                            <option value="4">Chốt đơn thất bại</option>
                    </select>
                </div>
                
                <div class="block-content" style={{background:'white',margin:'10px 0px 0px 0px',textAlign:'center'}}>
                    <table class="table table-vcenter">
                        <thead>
                            <tr>
                                <th class="text-center" style={{width: '40px',textAlign:'center'}}>#</th>
                                {/* <th class="text-center" style={{width: '70px',textAlign:'center'}}></th> */}
                                <th>UserComment</th>
                                <th>ID Comment</th>
                                <th>Nội dung comment</th>
                                <th>Thời gian</th>
                                <th>SDT</th>
                                <th>Ghi chú</th>
                                <th>Trạng thái</th>
                                <th style={{width:'70px',textAlign:'center'}}></th>
                            </tr>
                        </thead>
                        <tbody >
                            {order.map((item,index)=>{
                                return(
                                    <tr key={item.index} style={{height:'10px'}}>
                                        <td class="text-center" scope="row">{index+1}</td>
                                        {/* <td style={{visibility:"hidden"}}>{item.Id}</td> */}
                                        <td>{item.user_name}</td>
                                        <td>{item.comment_id}</td>
                                        <td>{item.message}</td>
                                        {
                                            item.timestamp !== 0 ?
                                            <td>
                                                {moment.unix(item.timestamp).format("MM/DD/YYYY hh:mm:ss")}
                                            </td>
                                            :<td>
                                            </td>
                                        }
                                        <td>{item.phone_comment}</td>
                                        <td>
                                        <textarea class="js-maxlength form-control js-maxlength-enabled" 
                                            id="example-maxlength10" name="example-maxlength10"
                                            rows="2" maxlength="100"
                                            data-always-show="true" value={item.note}>
                                        </textarea>
                                        </td>
                                        <td> 
                                            {
                                                item.status === 4 ?
                                                <div className="productText">
                                                <span class="badge badge-danger" style={{width:'110px'}}>Chốt đơn thất bại</span>
                                                </div>
                                                : null
                                            }
                                            {
                                                item.status === 1 ?
                                                <div className="productText">
                                                <span class="badge badge-primary"  style={{width:'110px'}}>Đang chờ </span>
                                                </div>
                                                : null
                                            }
                                            {
                                                item.status === 3 ?
                                                <div className="productText">
                                                <span class="badge badge-success" style={{width:'110px'}}>Đã chốt đơn</span>
                                                </div>
                                                : null
                                            }
                                            {
                                                item.status === 2 ?
                                                <div className="productText">
                                                <span class="badge badge-primary" style={{width:'110px'}}>Đã nhắn tin</span>
                                                </div>
                                                : null
                                            }
                                        </td>
                                       <td>
                                            <button type="button" class="btn btn-sm btn-secondary js-tooltip-enabled" data-toggle="tooltip" title="Chỉnh sửa"
                                             data-original-title="Edit" onClick={()=>this.setState({addShowEditComment:true,id_comment:item.comment_id,obj_id:item.Id})}>
                                                <i class="fa fa-edit"></i>
                                            </button>
                                       </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <EditComment show={this.state.addShowEditComment} 
                        onHide={addShowEditComment}  onExit={()=>this.refreshList(this.props.match.params.id,this.state.page_selected)} id={this.state.id_comment} obj_id={this.state.obj_id}/>
                </div>
                <div class="row">
                    <div style={{position:'absolute',left:'0px',width:'50%'}}> 
                        <select name="DataTables_Table_2_length" aria-controls="DataTables_Table_2"
                        class="custom-select custom-select-sm form-control form-control-sm" style={{width:'110px'}}
                        onChange={e=>this.refreshListChangeRowInPage(e)}>
                            <option value="15">15 / page</option>
                            <option value="20">20 / page</option>
                            <option value="25">25 / page</option>
                            <option value="30">30 / page</option>
                        </select>
                     </div>
                     <div style={{position:'absolute',left:'50%',width:'50%'}}>
                        <ReactPaginate
                            previousLabel={'Trước'}
                            nextLabel={'Sau'}
                            breakLabel={'...'}
                            pageCount={this.state.totalPage}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination justify-content-center'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            activeClassName={'active'}
                            hrefBuilder={''}
                            >
                        </ReactPaginate>
                    </div>
                </div>
            </main>
        )
    }
}