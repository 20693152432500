import React, { Component } from 'react';
import {Modal,Button, Row, Col, Form} from 'react-bootstrap';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
export class CreateOrder extends Component{
    constructor(props){
        super(props);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.state={token:'',page_name:'',id_page:''}
    }
    getInfo=(id_page)=>{
        return new Promise(resolve=>{
            resolve(
                fetch('https://postfanpage.lalasoft.vn/api/v1/channel/list?user_id='+id_page,
                {withCredentials: true,
                    credentials: 'include',
                    crossDomain: true}
                )
                .then(response=>response.json())
                .then(data=>{
                    this.setState({token:data.data[0].token,page_name:data.data[0].full_name}); 
                })
                .catch({
                })
            );
       });
    }
    getIdPage=(url)=>{
        return new Promise(resolve=>{
            resolve(
                fetch('https://api.ichot.vn/api/v1/findid?link='+url+'&apikey=59a0704f74d525d7ac59f1025901c8a8')
                .then(response=>response.json())
                .then(data=>{
                    this.setState({id_page:data.fb_id}); 
                    console.log(this.state.id_page);
                })
                .catch({
                })
            );
       });
    }
    async handleSubmit(event){
        try
        {
            event.preventDefault();
            let idpost;
            try
            {
                idpost=event.target.IdPost.value.match(/(posts\/|videos\/|story_fbid=|producer\/)(\d+)/)[2];
                await this.getIdPage(event.target.IdPost.value.match(/(.*?)\/videos/)[1]);
                await this.getInfo(this.state.id_page);
            }catch
            {

            }
            console.log(idpost);
            if(idpost==null)
            {
               this.state.page_name=event.target.IdPost.value.match(/\/@(.*?)\//)[1];
               this.state.id_page=event.target.IdPost.value.match(/\/@(.*?)\//)[1];
            }
            console.log(idpost);
            
            fetch('https://appcmtapi.lalasoft.vn/api/Order',{
                method:'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body:JSON.stringify({
                    id_post:idpost,
                    keyword:event.target.Keyword.value,
                    token:this.state.token,
                    user_id:this.props.user_id,
                    note:event.target.Note.value,
                    id_page:this.state.id_page,
                    page_name:this.state.page_name
                })
            })
            .then(res=>res.json())
            .then((result)=>{
                NotificationManager.success('Tạo đơn thành công');
                this.props.onHide();
            },
            (error)=>{
                NotificationManager.error('Tạo đơn thất bại');
            })
        }catch
        {
            NotificationManager.error('Tạo đơn thất bại,link bài viết sai');
        }
    }
    render(){
        return(
            <div className="container">
                <Modal
                {...this.props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered  >
                    <Modal.Header clooseButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                           Tạo đơn hàng
                        </Modal.Title>
                        <Button style={{background:'white',borderColor:'white' ,color:'rgba(0,0,0,.45)',fontSize:'1.5em'}}
                        onClick={this.props.onHide}>X</Button>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={6}>
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Group controlId="DepartmentName">
                                        <Form.Label style={{margin:'10px 0px 0px 0px'}}>Link bài viết</Form.Label>
                                        <br></br>
                                        <placeholder style={{color:'#B4B4B4'}}>Phần mềm sẽ thống kê các comment trong bài viết livestream này</placeholder>
                                        <Form.Control type="text" name="IdPost" required 
                                        placeholder="https://www.facebook.com/100018298549058/videos/6940701035956147"/>

                                        <Form.Label  style={{margin:'20px 0px 0px 0px'}}>Mã sản phẩm</Form.Label>
                                        <br></br>
                                        <placeholder style={{color:'#B4B4B4'}}>Phần mềm sẽ thống kê các comment theo nội dung bạn nhập</placeholder>
                                        <Form.Control type="text" name="Keyword" 
                                        placeholder="A01,A02,A03..."/>

                                        <Form.Label  style={{margin:'20px 0px 0px 0px'}}>Ghi chú</Form.Label>
                                        <textarea class="js-maxlength form-control js-maxlength-enabled" id="example-maxlength10" name="Note" rows="3"
                                         maxlength="100" data-always-show="true"></textarea>

                                    </Form.Group>
                                    <Form.Group> 
                                        <Button variant="primary" type="submit" style={{width:'90px',margin:'15px 10px 0px 0px'}}>
                                                Xác nhận
                                        </Button>
                                        <Button variant="danger"  style={{width:'90px',margin:'15px 0px 0px 0px'}} onClick={this.props.onHide}>
                                            Hủy Bỏ
                                        </Button>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <NotificationContainer/>
            </div>
        )
    }
}