import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import {CreateOrder} from './CreateOrder';
import ReactPaginate from 'react-paginate';
import './assets/css/codebase.css';
import moment from 'moment';
import { EditComment } from "./EditComment";
export class Page extends Component{
    constructor(props){
        super(props);
        this.state={page:[],totalPost:0,totalPage:0}
    }
    // refreshList = () => {
    //     fetch('http://localhost:6001/WeatherForecast')
    //     .then(response=>response.json())
    //     .then(data=>{
    //         this.setState({page:data.data,totalPost:data.total,totalPage:(data.total/20)});
    //     });
    // }
    refreshList = () => {
        fetch('https://postfanpage.lalasoft.vn/api/v1/channel/list?acc_type=5&limit=20&page=1',{withCredentials: true,
        credentials: 'include',
        crossDomain: true,})
        .then(response=>response.json())
        .then(data=>{
            this.setState({page:data.data,totalPost:data.total,totalPage:(data.total/20)});
        });
    }
    componentDidMount(){
        this.refreshList();
    }
    handlePageClick=(data)=>{
        this.refreshList(this.props.match.params.id,data.selected+1);
    }
    changePage=(id_post)=>{
        this.props.history.push("/page"+"/"+id_post);
    }
    render(){
        let {page}=this.state;
    return(
            <main id="main-container">
                <div class="block-header block-header-default">
                    <h3 class="block-title">Tổng số fanpage : <small>{this.state.totalPost}</small></h3>
                </div>
                <div class="block-content" style={{background:'white'}}>
                    <table class="table table-vcenter">
                        <thead>
                            <tr>
                                <th class="text-center" style={{width: '50px;'}}>#</th>
                                <th>Ảnh</th>
                                <th>ID</th>
                                <th>Tên Page</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody >
                            {page.map((item,index)=>{
                                return(
                                    <tr key={item.index}>
                                        <th class="text-center" scope="row">{index+1}</th>
                                        <td>
                                        <img
                                            src={item.avatar}
                                            width={40}
                                            alt='Page'
                                            style={{borderRadius:'50%'}}
                                        />
                                        </td>
                                        <td>{item.user_id}</td>
                                        <td>{item.full_name}</td>
                                        <td>
                                           <button type="button" class="btn btn-primary" toggle="tooltip"  data-placement="left" title="Quét Comment" data-original-title="Left Tooltip" onClick={()=>this.changePage(item.user_id)}>
                                              <i class="fa fa-comments"></i>
                                           </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageCount={this.state.totalPage}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                        hrefBuilder={''}
                        >
                    </ReactPaginate>
                </div>
            </main>
        )
    }
}