import React, { Component } from "react";
import {Modal,Button, Row, Col, Form, Dropdown} from 'react-bootstrap';
import './assets/css/codebase.css';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
export class EditComment extends Component{
    constructor(props){
        super(props);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.state={user_id:'',token:'',selectedValue:'',note:'',id:''}
    }
    handleSubmit(event){
        if(this.props.id==null)
        {
            this.state.id=this.props.obj_id;
        }
        else
        {
            this.state.id=this.props.id;
        }
        try
        {
            event.preventDefault();
            fetch('https://appcmtapi.lalasoft.vn/api/Comment/Update',{
                method:'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body:JSON.stringify({
                    id:this.state.id,
                    status:parseInt(event.target.Status.value),
                    note:event.target.Note.value,
                })
            })
            .then(res=>res.json())
            .then((result)=>{
                //NotificationManager.success('Cập nhật thành công');
                this.props.onHide();
            },
            (error)=>{
                NotificationManager.error('Cập nhật thất bại');
            })
        }catch
        {
        }
    }
    handleChange=event=>{
        this.setState({selectedValue:event.target.value});
    }
    render(){
        return(
            <div className="container">
                <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                    <Modal.Header clooseButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                           Cập nhật trạng thái
                        </Modal.Title>
                        <Button style={{background:'white',borderColor:'white' ,color:'rgba(0,0,0,.45)',fontSize:'1.5em'}}
                        onClick={this.props.onHide}>X</Button>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={6}>
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Group controlId="DepartmentName">
                                        <Form.Label>Trạng thái</Form.Label>
                                        <select class="js-select2 form-control js-select2-enabled select2-hidden-accessible"  id="example-select2" name="Status" 
                                        style={{width: '100%;',cursor:'n-resize' ,margin:'0px 0px 15px 0px'}} data-placeholder="Choose one.." data-select2-id="example-select2" tabindex="-1" aria-hidden="true"
                                        onChange={this.handleChange}>
                                                    <option value="1" data-select2-id="14">Đang chờ</option>
                                                    <option value="2" data-select2-id="14">Đã nhắn tin</option>
                                                    <option value="3" data-select2-id="15">Đã chốt đơn</option>
                                                    <option value="4" data-select2-id="16">Chốt đơn thất bại</option>
                                        </select>
                                        <Form.Label>Ghi chú</Form.Label>
                                        <textarea class="js-maxlength form-control js-maxlength-enabled" id="example-maxlength10" name="Note" rows="3"
                                         maxlength="100" data-always-show="true"></textarea>
                                    </Form.Group>
                                    <Form.Group>
                                        <Button variant="primary" type="submit" style={{width:'90px',margin:'15px 10px 0px 0px'}}>
                                            Xác nhận
                                        </Button>
                                        <Button variant="danger"  style={{width:'90px',margin:'15px 0px 0px 0px'}} onClick={this.props.onHide}>
                                            Hủy Bỏ
                                        </Button>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}