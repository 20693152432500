import React, { Component } from "react";
import {CreateOrder} from './CreateOrder';
import { useHistory } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import './assets/css/codebase.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import moment from 'moment';
export class Main extends Component{
    constructor(props){
        super(props);
        this.state={order:[], addOrderShow:false, editOrderShow:false,page_selected:1,totalPost:0,totalPage:0,code:0,user_id:'',token:'',
        user:[],page:[],id_page:'',row_in_page:15,is_auto_refresh:true,status_view:0}
    }

    handleChangeSelectStatus=(event)=>{ 
        return new Promise(resolve=>{
            resolve(this.setState({status:event.target.value}));
        });
    }
    async refreshListChangeCode(event)
    {
        await this.handleChangeSelectCode(event);
        this.refreshList(this.props.match.params.id,this.state.page_selected)
    }
    getDetail=()=>{
        return new Promise(resolve=>{
            resolve(
                fetch('https://postfanpage.lalasoft.vn/api/v1/user/detail',{withCredentials: true,
                credentials: 'include',
                crossDomain: true,})
                .then(response=>response.json())
                .then(data=>{
                    this.setState({user:data,user_id:data.user._id});
                })
            );
        });
    }
    getPage = () => {
        fetch('https://postfanpage.lalasoft.vn/api/v1/channel/list?acc_type=5&limit=20&page=1',{withCredentials: true,
        credentials: 'include',
        crossDomain: true,})
        .then(response=>response.json())
        .then(data=>{
            this.setState({page:data.data});
        });
    }
    refreshList = (page_index,id_page) => {
        fetch('https://appcmtapi.lalasoft.vn/api/order/GetOrder?page='+page_index+'&limit='+this.state.row_in_page+'&user_id='+this.state.user_id+'&id_page='+id_page)
        .then(response=>response.json())
        .then(data=>{
            this.setState({order:data.orders,totalPost:data.total,totalPage:((data.total/this.state.row_in_page))});
        })
        .catch({});
    }
    changePage=(id_post,status_view)=>{
        if(status_view===3)
        {
            NotificationManager.error('Bài đăng lỗi');
        }
        else
        {
            this.props.history.push("/post"+"/"+id_post);
        }
    }
    Log=(url)=>{
        console.log(1);
        console.log(url.match(/(.*?)\/videos/)[1]);
    }
    async componentDidMount(){
        this.getPage();
        await this.getDetail();
        this.refreshList(1,'');
    }
    handlePageClick=(data)=>{
        this.state.page_selected=data.selected+1;
        this.refreshList(data.selected+1);
    }
    handleChangeSelect=(event)=>{ 
        return new Promise(resolve=>{
            resolve(this.setState({id_page:event.target.value}));
        });
    }
    async refreshListChangeIdPage(event)
    {
        await this.handleChangeSelect(event);
        this.refreshList(this.state.page_selected,this.state.id_page)
    }
    handleChangeSelectInPage=(event)=>{
        return new Promise(resolve=>{
            resolve(this.setState({row_in_page:event.target.value}));
        });
    }
    async refreshListChangeRowInPage(event)
    {
        await this.handleChangeSelectInPage(event);
        this.refreshList(this.state.page_selected,this.state.id_page)
    }
    render(){
        let {order}=this.state;
        let{page}=this.state;
        let addOrderClose=()=>this.setState({addOrderShow:false});
        return(
            <main id="main-container">
                <div class="block-header block-header-default">
                    <h1 class="block-title">Quét comment : {this.state.totalPost}</h1>
                </div>
                <div class="mb-10">
                    <button type="button" class="btn btn-primary min-width-20" toggle="tooltip"  data-placement="left" title="Thêm đơn hàng" data-original-title="Left Tooltip" 
                     onClick={()=>this.setState({addOrderShow:true})}>
                        <i class="fa fa-plus mr-5"></i>
                    </button>
                    <button type="button" class="btn btn-primary min-width-20" toggle="tooltip"  data-placement="left" title="Làm mới" data-original-title="Left Tooltip" 
                    onClick={()=>this.refreshList(this.state.page_selected,this.state.id_page)}>
                        <i class="si si-reload fa-lg"></i>
                    </button>
                    <CreateOrder show={this.state.addOrderShow} user_id={this.state.user_id}
                    onHide={addOrderClose} onExit={()=>this.refreshList(this.state.page_selected,'')} />
                    
                </div>
                <div class="mb-5">
                 
                    <select class="form-control" name="page" style={{width:'150px',margin:'0px 20px 0px 20px',display:'inline-block'}}
                    onClick={e=>this.refreshListChangeIdPage(e)}>
                            <option value="">Tên Page</option>
                            {page.map((item)=>{
                                return(
                                    <option value={item.user_id}>{item.full_name}</option>
                                )
                            })}
                    </select>
              </div>
                <div class="block-content" style={{background:'white'}}>
                    <table class="table table-vcenter" style={{textAlign:'center'}}>
                        <thead>
                            <tr>
                                <th  style={{width: '42px',textAlign:'center'}}>#</th>
                                <th>ID bài viết</th>
                                <th>Nội dung</th>
                                <th>Tên Page</th>
                                <th>Từ khóa sản phẩm</th>
                                <th>Thời gian tạo</th>
                                <th>Tổng số comment</th>
                                <th>Ghi chú</th>
                                <th class="d-none d-sm-table-cell" style={{width: '15%;'}}>Trạng thái</th>
                                <th class="text-center" style={{width: '70px;'}}>Chức năng</th>
                            </tr>
                        </thead>
                        <tbody >
                        <td></td>
                            {order.map((item,index)=>{
                                return(
                                    <tr key={item.index} >
                                        <th class="text-center" scope="row">{index+1}</th>
                                        <td>{item.id_post}</td>
                                        <td>{item.description}</td>
                                        <td>{item.page_name}</td>
                                        <td>{item.keyword}</td>
                                        <td>{ 
                                              item.created_time!==0 ?
                                              moment.unix(item.created_time).format("MM/DD/YYYY hh:mm:ss")
                                              :null
                                            }</td>
                                        <td>{item.total_comment}</td>
                                        <td> <textarea class="js-maxlength form-control js-maxlength-enabled" 
                                            id="example-maxlength10" name="example-maxlength10"
                                            rows="2" maxlength="100"
                                            data-always-show="true" value={item.note}>
                                        </textarea></td>
                                        <td class="d-none d-sm-table-cell"> 
                                            {
                                                item.status === 3 ?
                                                <div className="productText">
                                                <span class="badge badge-danger">Thất bại</span>
                                                </div>
                                                : null
                                            }
                                            {
                                                item.status === 0 ?
                                                <div className="productText">
                                                <span class="badge badge-primary">Đang chờ</span>
                                                </div>
                                                : null
                                            }
                                            {
                                                item.status === 2 ?
                                                <div className="productText">
                                                <span class="badge badge-success">Thành công</span>
                                                </div>
                                                : null
                                            }
                                            {
                                                item.status === 1 ?
                                                <div className="productText">
                                                <span class="badge badge-primary">Đang xử lí</span>
                                                </div>
                                                : null
                                            }
                                        </td>
                                        <td class="text-center" data-title={item.id_post}>
                                                <button type="button" class="btn btn-sm btn-secondary js-tooltip-enabled" data-toggle="tooltip" title="Xem chi tiết" data-original-title=""
                                                onClick={()=>this.changePage(item.id_post,item.status)}>
                                                    <i class="fa fa-align-justify"></i>
                                                </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div style={{position:'absolute',left:'0px',width:'50%'}}> 
                     
                    <select name="DataTables_Table_2_length" aria-controls="DataTables_Table_2"
                     class="custom-select custom-select-sm form-control form-control-sm" style={{width:'110px'}}
                    onChange={e=>this.refreshListChangeRowInPage(e)}>
                        <option value="15">15 / page</option>
                        <option value="20">20 / page</option>
                        <option value="25">25 / page</option>
                        <option value="30">30 / page</option>
                    </select>
                    </div>
                    <div style={{position:'absolute',left:'50%',width:'50%'}}>
                        <ReactPaginate
                            previousLabel={'Trước'}
                            nextLabel={'Sau'}
                            breakLabel={'...'}
                            pageCount={this.state.totalPage}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination justify-content-center'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            activeClassName={'active'}
                            hrefBuilder={''}
                            >
                        </ReactPaginate>
                    </div>
                </div>
            </main>
        )
    }
}